<template>
  <v-menu
    ref="menu"
    v-model="showMenu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        readonly
        v-bind="attrs"
        v-on="on"
        :value="formattedDate"
        :prepend-icon="icons.calendar"
        :error-messages="errorMessage"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="showMenu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="update"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'BirthDateFieldComponent',

  props: {
    errorMessage: {
      required: true
    },

    value: {
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  emits: ['change'],

  data() {
    return {
      showMenu: false,
      date: this.value,
      icons: {
        calendar: mdiCalendar
      }
    }
  },

  computed: {
    formattedDate() {
      return new Date(this.date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }
  },

  methods: {
    update(date) {
      this.$refs.menu.save(this.date)
      this.$emit('change', this.date)
    }
  }
}
</script>
